import { baseEnvironment } from 'src/environments/baseEnvironment';

export const environment = {
  ...baseEnvironment,
  organizationPrefix: 'GRS',
  organizationUUID: '8cdbe90a-4d5c-4e04-9975-bd776fd57084',
  activeLanguage: 'en-US',
  multipleAdminCommunity: false,
  requiredField: {},
  systemToken: '',
  htmlFields: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ header: 1 }, { header: 2 }],
    ['blockquote'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }, 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
    ['clean'],
  ],
  basicConfiguration: undefined,
  googleAPIKey: '',
  manageTemplates: false,
  manageUserFilter: false,

  productEditorTabIcons: ['r', 'z', 'M', 'x', 'm'],
  pwaThemeColor: 'rgba(0, 72, 205, 1.0)',
};
